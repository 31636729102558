import React from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";
import CustomLocalizedLink from "../locales/custom-localized-link";

const ThreeButtonsCompare = () => {
  const {t} = useTranslation();
  return (
      <div className="three-buttons">

        <CustomLocalizedLink
            label={t('see_all_models')}
            goto={'/products/'}
            cls={'btn btn--grey'}/>

        <CustomLocalizedLink
            label={t('help_me_choose')}
            goto={'/quiz/'}
            cls={'btn btn--grey'}/>

        <CustomLocalizedLink
            label={t('book_consultation')}
            goto={'/contact/consultation/'}
            cls={'btn btn--orange page-brochure-button book-consultation'}/>
      </div>
  )
}
export default ThreeButtonsCompare;