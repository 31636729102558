import React, {useState} from 'react';
import pulldownArrow from '../../dealers/images/filter-menu-down.svg';
import pulldownArrowWhite from '../../dealers/images/filter-menu-down-white.svg';
import {useTranslation} from "gatsby-plugin-react-i18next";


const CompareMenu = ({label, cls, menu, menuOpen, handleMenuClick, handleItemClick, findMenuMatch}) => {
  const {t} = useTranslation();
  const lockCompareMenu = [
    {
      section: 'electronic_locks',
      items: ['Aspire', 'Curve', 'Orbit', 'Versa'],
    },
    {
      section: 'hardwired_locks',
      items: ['Pivot'],
    },
    {
      section: 'mechanical_locks',
      items: ['Cleo', 'Mech'],
    },
  ];
  return (
      <div className={'dropdown ' + cls} onClick={(event) => handleMenuClick(event, menu)}>
        <div className={menuOpen ? 'dropdown-label open' : 'dropdown-label'}>
          <span>{label}</span>
          <img src={menuOpen ? pulldownArrowWhite : pulldownArrow} alt="arrow" width={'12'} height={'7'}/>
        </div>
        <div className={menuOpen ? 'dropdown-menu open' : 'dropdown-menu'}>
          <div>
            {lockCompareMenu.map((category, index) => (
                <React.Fragment key={index}>
          <span
              className="section"
              dangerouslySetInnerHTML={{__html: t(category.section)}}
          />
                  {category.items.map((item, i) => (

                      <span className={findMenuMatch(item) ? 'lock-item taken' : 'lock-item'}
                            key={i}
                            data-value={item}
                            onClick={(event) => handleItemClick(event, menu, item)}
                      >
                        {item}
                      </span>
                  ))}
                </React.Fragment>
            ))}
          </div>
        </div>
      </div>
  )
};

export default CompareMenu;
