import React, {useState, useEffect} from 'react';
import {useTranslation, Link} from 'gatsby-plugin-react-i18next';
import CompareMenu from "./compare-menu";
import CompareSheet from "./compare-sheet";
import {replaceCharWithChar} from '../../../utils/utils';
import {isInnerWidthMobile} from '../../../utils/utils';
//images
import interfaceKeyRfidBle from './images/interface/interface-keypad-rfid-ble.png';
import interfaceDialCombo from './images/interface/interface-dial-combo.png';
import interfaceRfid from './images/interface/interface-rfid.png';
import mechanismCamDeadboltDeadlatch from './images/lock-mechanism/mechanism-cam-deadbolt-deadlatch.png';
import mechanismCamLock from './images/lock-mechanism/mechanism-cam-lock.png';
import mechanismDeadboltDeadlatch from './images/lock-mechanism/mechanism-deadbolt-deadlatch.png';
import mechanismHookLatch from './images/lock-mechanism/mechanism-hook-latch.png';
import managementBasicAdvancedNetwork from './images/management/management-basic-advanced-network.png';
import managementBasicNetwork from './images/management/management-basic-networked.png';
import managementFlexKey from './images/management/management-flex-key.png';
import managementLaserKey from './images/management/management-laser-key.png';
import mountingHardwiredInsideMount from './images/mounting-install/mounting-hardwired-inside-mount.png';
import mountingRecessedRetrofit from './images/mounting-install/mounting-recessed-retrofit.png';
import mountingSurfaceRecessedRetrofit from './images/mounting-install/mounting-surface-recessed-retrofit.png';
import mountingSurfaceRetrofit from './images/mounting-install/mounting-surface-retrofit.png';
import credentialsRfidMc from './images/credentials/credentials-rfid-mc.png';
import credentialsUserCode from './images/credentials/credentials-user-code.png';
import credentialsUserCodeRfidMc from './images/credentials/credentials-usercode-rfid-mc.png';
import colorBlack from './images/color/color-black.png';
import colorBrushedBlack from './images/color/color-brushed-black.png';
import colorBlackWhite from './images/color/color-black-white.png';
import power4PremAA from './images/power/4-prem-aa.png';
import powerBatteryFree from './images/power/battery-free.png';
import powerHardwired from './images/power/hardwired.png';

const CompareLocks = () => {
  const {t} = useTranslation();
  const [defaultMenuLabel] = useState(t('choose_lock_model'));
  const [menuOneOpen, setMenuOneOpen] = useState(false);
  const [menuTwoOpen, setMenuTwoOpen] = useState(false);
  const [menuThreeOpen, setMenuThreeOpen] = useState(false);
  const [menuOneSelection, setMenuOneSelection] = useState(defaultMenuLabel);
  const [menuTwoSelection, setMenuTwoSelection] = useState(defaultMenuLabel);
  const [menuThreeSelection, setMenuThreeSelection] = useState(defaultMenuLabel);
  const [menuOneSelectionList, setMenuOneSelectionList] = useState('');
  const [menuTwoSelectionList, setMenuTwoSelectionList] = useState('');
  const [menuThreeSelectionList, setMenuThreeSelectionList] = useState('');
  const [menuSelections, setMenuSelections] = useState({menuOne:'', menuTwo:'', menuThree:''});
  const [sheetOneFirstOpen, setSheetOneFirstOpen] = useState(false);
  const [sheetTwoFirstOpen, setSheetTwoFirstOpen] = useState(false);
  const [sheetThreeFirstOpen, setSheetThreeFirstOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [quizResults, setQuizResults] = useState(false);
  const [quizTopLock, setQuizTopLock] = useState('');
  const [quizRecOneLock, setQuizRecOneLock] = useState('');
  const [quizRecTwoLock, setQuizRecTwoLock] = useState('');

  const handleMenuClick = (e, whichMenu) => {
    switch (whichMenu) {
      case 'one':
        setMenuOneOpen(!menuOneOpen);
        break;
      case 'two':
        setMenuTwoOpen(!menuTwoOpen);
        break;
      default:
        setMenuThreeOpen(!menuThreeOpen);
    }
  }
  const handleMenuItemClick = (e, whichMenu, item) => {
    const timer = 800;
    switch (whichMenu) {
      case 'one':
        setMenuOneSelectionList(prevState => lockList.find(lock => lock.lockName === item.toLowerCase()));
        setMenuSelections((prevState) => ({
          ...prevState,
          menuOne: '' // Change 'menuOne' to the property you want to update
        }));
        setTimeout(() => {
          setMenuOneSelection(item);
          setMenuSelections((prevState) => ({
            ...prevState,
            menuOne: item // Change 'menuOne' to the property you want to update
          }));
        }, sheetOneFirstOpen ? timer : 0);
        setSheetOneFirstOpen(true);
        break;
      case 'two':
        setMenuTwoSelectionList(prevState => lockList.find(lock => lock.lockName === item.toLowerCase()));
        setMenuSelections((prevState) => ({
          ...prevState,
          menuTwo: '' // Change 'menuOne' to the property you want to update
        }));
        setTimeout(() => {
          setMenuTwoSelection(item);
          setMenuSelections((prevState) => ({
            ...prevState,
            menuTwo: item // Change 'menuOne' to the property you want to update
          }));
        }, sheetTwoFirstOpen ? timer : 0)
        setSheetTwoFirstOpen(true);
        break;
      default:
        setMenuThreeSelectionList(prevState => lockList.find(lock => lock.lockName === item.toLowerCase()));
        setMenuSelections((prevState) => ({
          ...prevState,
          menuThree: '' // Change 'menuOne' to the property you want to update
        }));
        setTimeout(() => {
          setMenuThreeSelection(item);
          setMenuSelections((prevState) => ({
            ...prevState,
            menuThree: item // Change 'menuOne' to the property you want to update
          }));
        }, sheetThreeFirstOpen ? timer : 0)
        setSheetThreeFirstOpen(true);
    }
    shouldWeScroll();
  }
  const windowClickHandler = (e) => {
    let typeMenu = document.getElementsByClassName('locks-filter-menus')[0];
    if (!typeMenu.contains(e.target)) {
      setMenuOneOpen(false);
      setMenuTwoOpen(false);
      setMenuThreeOpen(false);
    }
  }
  const createLockClass = (lock) => {
   return replaceCharWithChar(lock, ' ', '-').toLowerCase();
  }
  const  createMarkup = (markup) => {
    return {__html: t(markup)};
  }
  const findMatchInObject = (searchValue) => {
    for (const key in menuSelections) {
      if (Object.hasOwn(menuSelections, key)) { // Ensure it's not an inherited property
        if (menuSelections[key] === searchValue) {
          return true; // Match found
        }
      }
    }
    return false; // No match found
  }
  const shouldWeScroll = () => {
    if(isMobile) {
      if(window.scrollY > 272) {
        window.scrollTo({
          top: 232,
          left: 0,
          behavior: 'smooth' // or 'instant'
        });
      }
    } else {
      if(window.scrollY > 287) {
        window.scrollTo({
          top: 240,
          left: 0,
          behavior: 'smooth' // or 'instant'
        });
      }
    }
  }
  const compareQuizResults = (products) => {
    setQuizTopLock(products[0]);
    setQuizRecOneLock(products[1]);
    handleMenuItemClick(null, 'one', products[0]);
    handleMenuItemClick(null, 'two', products[1]);
    if(!isInnerWidthMobile()) {
      setQuizRecTwoLock(products[2]);
      handleMenuItemClick(null, 'three', products[2]);
    }

  }

  const [lockList, setLockList] = useState(
      [
        {
          'lockName': 'aspire',
          'url': '/products/smart-locks/aspire/',
          'interface': ['keypad_rfid_ble', interfaceKeyRfidBle],
          'credentials': ['user_code_rfid_mobile_credential', credentialsUserCodeRfidMc],
          'lockMechanism': ['deadbolt_deadlatch', mechanismDeadboltDeadlatch],
          'power': ['power_4_aa', power4PremAA],
          'management': ['basic_advanced_networked', managementBasicAdvancedNetwork],
          'mountingInstall': ['surface_retrofit', mountingSurfaceRetrofit],
          'color': ['brushed_nickel_matte_black', colorBrushedBlack]
        },
        {
          'lockName': 'curve',
          'url': '/products/smart-locks/curve/',
          'interface': ['keypad_rfid_ble', interfaceKeyRfidBle],
          'credentials': ['user_code_rfid_mobile_credential', credentialsUserCodeRfidMc],
          'lockMechanism': ['deadbolt_deadlatch', mechanismDeadboltDeadlatch],
          'power': ['power_4_aa', power4PremAA],
          'management': ['basic_advanced_networked', managementBasicAdvancedNetwork],
          'mountingInstall': ['surface_retrofit', mountingSurfaceRetrofit],
          'color': ['brushed_nickel_matte_black', colorBrushedBlack]
        },
        {
          'lockName': 'orbit',
          'url': '/products/smart-locks/orbit/',
          'interface': ['rfid_ble', interfaceRfid],
          'credentials': ['rfid_mobile_credential', credentialsRfidMc],
          'lockMechanism': ['deadbolt_deadlatch', mechanismDeadboltDeadlatch],
          'power': ['power_4_123a', power4PremAA],
          'management': ['basic_advanced_networked', managementBasicAdvancedNetwork],
          'mountingInstall': ['recessed_retrofit', mountingRecessedRetrofit],
          'color': ['black_white', colorBlackWhite]
        },
        {
          'lockName': 'versa',
          'url': '/products/smart-locks/versa/',
          'interface': ['keypad_rfid_ble', interfaceKeyRfidBle],
          'credentials': ['user_code_rfid_mobile_credential', credentialsUserCodeRfidMc],
          'lockMechanism': ['cam_lock', mechanismCamLock],
          'power': ['power_4prem_lithium', power4PremAA],
          'management': ['basic_advanced_networked', managementBasicAdvancedNetwork],
          'mountingInstall': ['surface_recessed_retrofit', mountingSurfaceRecessedRetrofit],
          'color': ['brushed_nickel_matte_black', colorBrushedBlack]
        },
        {
          'lockName': 'pivot',
          'url': '/products/smart-locks/pivot/',
          'interface': ['keypad_via_kiosk_rfid', interfaceKeyRfidBle],
          'credentials': ['user_code_rfid_mobile_credential', credentialsUserCodeRfidMc],
          'lockMechanism': ['hook_latch', mechanismHookLatch],
          'power': ['power_hardwired', powerHardwired],
          'management': ['basic_networked', managementBasicNetwork],
          'mountingInstall': ['inside_mount_hardwired', mountingHardwiredInsideMount],
          'color': ['black', colorBlack]
        },
        {
          'lockName': 'cleo',
          'url': '/products/mechanical-locks/cleo/',
          'interface': ['dial_combination', interfaceDialCombo],
          'credentials': ['user_code', credentialsUserCode],
          'lockMechanism': ['cam_deadbolt_deadlatch', mechanismCamDeadboltDeadlatch],
          'power': ['power_battery_free', powerBatteryFree],
          'management': ['electronic_flex_key', managementFlexKey],
          'mountingInstall': ['surface_recessed_retrofit', mountingSurfaceRecessedRetrofit],
          'color': ['brushed_nickel_matte_black', colorBrushedBlack]
        },
        {
          'lockName': 'mech',
          'url': '/products/mechanical-locks/mech/',
          'interface': ['dial_combination', interfaceDialCombo],
          'credentials': ['user_code', credentialsUserCode],
          'lockMechanism': ['cam', mechanismCamLock],
          'power': ['power_battery_free', powerBatteryFree],
          'management': ['laser_cut_key', managementLaserKey],
          'mountingInstall': ['recessed_retrofit', mountingRecessedRetrofit],
          'color': ['brushed_nickel_matte_black', colorBrushedBlack]
        }
      ]
  )
  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobile(isInnerWidthMobile());
    }
    checkScreenSize();
    window.addEventListener("resize", checkScreenSize);
    window.addEventListener('click', windowClickHandler);
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("products")) {
      const products = urlParams.get("products")?.split(",") || [];
      compareQuizResults(products);
    }
    if(urlParams.has("quiz")) {
      const products = urlParams.get("quiz")?.split(",") || [];
      setQuizResults(true);
      compareQuizResults(products);
    }
    return () => {
      window.removeEventListener('click', windowClickHandler);
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);
  return (
      <>
        <div class="hider" />
        <div className={'dropdown locks-filter-menus'}>
          <CompareMenu
              label={menuOneSelection}
              cls={'lock-menu one'}
              menu={'one'}
              menuOpen={menuOneOpen}
              handleMenuClick={handleMenuClick}
              handleItemClick={handleMenuItemClick}
              findMenuMatch={findMatchInObject}
          />
          <CompareMenu
              label={menuTwoSelection}
              cls={'lock-menu two'}
              menu={'two'}
              menuOpen={menuTwoOpen}
              handleMenuClick={handleMenuClick}
              handleItemClick={handleMenuItemClick}
              findMenuMatch={findMatchInObject}
          />
          <CompareMenu
              label={menuThreeSelection}
              cls={'lock-menu three'}
              menu={'three'}
              menuOpen={menuThreeOpen}
              handleMenuClick={handleMenuClick}
              handleItemClick={handleMenuItemClick}
              findMenuMatch={findMatchInObject}
          />
        </div>
        <div class="compare-sheets">
          <CompareSheet
              menu={'one'}
              lock={createLockClass(menuSelections.menuOne)}
              lockList={menuOneSelectionList}
              isMobile={isMobile}
              quizResults={quizResults}
              quizTopLock={quizTopLock}
              quizRecOne={quizRecOneLock}
              quizRecTwo={quizRecTwoLock}
          />
          <CompareSheet
              menu={'two'}
              lock={createLockClass(menuSelections.menuTwo)}
              lockList={menuTwoSelectionList}
              isMobile={isMobile}
              quizResults={quizResults}
              quizTopLock={quizTopLock}
              quizRecOne={quizRecOneLock}
              quizRecTwo={quizRecTwoLock}
          />
          <CompareSheet
              menu={'three'}
              lock={createLockClass(menuSelections.menuThree)}
              lockList={menuThreeSelectionList}
              isMobile={isMobile}
              quizResults={quizResults}
              quizTopLock={quizTopLock}
              quizRecOne={quizRecOneLock}
              quizRecTwo={quizRecTwoLock}
          />
        </div>
      </>
  )
};

export default CompareLocks;