import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import ThreeButtonsCompare from "../../../components/pages/ThreeButtonsCompare";
import {graphql} from 'gatsby';
import CompareLocks from "../../../components/products/compare-locks/CompareLocks";


const IndexPage = () => {

  const {t} = useTranslation();

  return (
      <Layout>
        <Seo
            title={t("compare_title")}
            description={t('des_compare')}
        />
        <div className="compare-locks">
          <section className="">
            <div class="container">
              <h1 dangerouslySetInnerHTML={{__html:t('compare_headline')}}/>
              <p dangerouslySetInnerHTML={{__html:t('compare_headline_copy')}} />
              <ThreeButtonsCompare />
            </div>
          </section>
          <section className={'compare-locks-app'}>
            <div class="container">
              <CompareLocks />
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
