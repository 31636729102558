import React, {useState} from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";
import CustomLocalizedLink from "../../locales/custom-localized-link";
import {replaceCharWithChar} from "../../../utils/utils";


const CompareSheet = ({menu, lock, lockList, isMobile, quizTopLock, quizRecOne, quizRecTwo, quizResults}) => {
  const {t} = useTranslation();
  const reverseLockClass = (lock) => {
    return replaceCharWithChar(lock, '-', ' ').toLowerCase();
  }

  return (
      <div className={'compare-sheet '+menu+' '+lock}>
        <div className={quizResults ? 'lock-hero quiz' : 'lock-hero'}>
          {
            quizResults &&
              menu === 'one' &&
              <p className={'top-lock'}>{quizTopLock.toLowerCase() === lock ? t('recommended') : ''}</p>
          }
          {
              quizResults &&
              menu === 'two' &&
              <p>{quizRecOne.toLowerCase() === lock ? t('might_consider') : ''}</p>
          }
          {
              quizResults &&
              menu === 'three' &&
              <p>{quizRecTwo.toLowerCase() === lock ? t('might_consider') : ''}</p>
          }
        </div>
        <div className={'interface '+lock}>
          <h2 dangerouslySetInnerHTML={{__html:t('interface')}} />
          <figure>
            <img src={lockList.interface?.[1]} width={'203'} height={'99'} alt={t(lockList.interface?.[0])} loading={'lazy'}/>
            <figcaption dangerouslySetInnerHTML={{__html:t(lockList.interface?.[0])}} />
          </figure>
        </div>
        <div className="credentials">
          <h2 dangerouslySetInnerHTML={{__html:t('credentials')}} />
          <figure>
            <img src={lockList.credentials?.[1]} width={'203'} height={'99'} alt={t(lockList.credentials?.[0])} loading={'lazy'}/>
            <figcaption dangerouslySetInnerHTML={{__html:t(lockList.credentials?.[0])}} />
          </figure>
        </div>
        <div className="lock-mechanism">
          <h2 dangerouslySetInnerHTML={{__html:t('lock_mechanism')}} />
          <figure>
            <img src={lockList.lockMechanism?.[1]} width={'203'} height={'99'} alt={t(lockList.lockMechanism?.[0])} loading={'lazy'}/>
            <figcaption dangerouslySetInnerHTML={{__html:t(lockList.lockMechanism?.[0])}} />
          </figure>
        </div>
        <div className="power">
          <h2 dangerouslySetInnerHTML={{__html:t('power')}} />
          <figure>
            <img src={lockList.power?.[1]} width={'203'} height={'99'} alt={t(lockList.power?.[0])} loading={'lazy'}/>
            <figcaption dangerouslySetInnerHTML={{__html:t(lockList.power?.[0])}} />
          </figure>
        </div>
        <div className="management">
          <h2 dangerouslySetInnerHTML={{__html:t('sheet_lock_management')}} />
          <figure>
            <img src={lockList.management?.[1]} width={'203'} height={'99'} alt={t(lockList.management?.[0])} loading={'lazy'}/>
            <figcaption dangerouslySetInnerHTML={{__html:t(lockList.management?.[0])}} />
          </figure>
        </div>
        <div className="mounting-install">
          <h2 dangerouslySetInnerHTML={{__html:t('mounting_install')}} />
          <figure>
            <img src={lockList.mountingInstall?.[1]} width={'203'} height={'99'} alt={t(lockList.mountingInstall?.[0])} loading={'lazy'}/>
            <figcaption dangerouslySetInnerHTML={{__html:t(lockList.mountingInstall?.[0])}} />
          </figure>
        </div>
        <div className="color">
          <h2 dangerouslySetInnerHTML={{__html:t('color')}} />
          <figure>
            <img src={lockList.color?.[1]} width={'203'} height={'99'} alt={t(lockList.color?.[0])} loading={'lazy'}/>
            <figcaption dangerouslySetInnerHTML={{__html:t(lockList.color?.[0])}} />
          </figure>
        </div>
        <div class="compare-cta-butt">
          <CustomLocalizedLink
              label={t('learn_more')+' about '+reverseLockClass(lock)}
              goto={lockList.url ? lockList.url : ''}
              cls={isMobile ? 'btn btn--no-btn' : 'btn btn--grey'}/>
        </div>
      </div>
  )
};

export default CompareSheet;
